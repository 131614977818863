import * as React from "react";
import { primaryColor } from "../../scss/colors.scss";

function SvgComponent({ fill = primaryColor }) {
  return (
    <svg
      width="47"
      height="26"
      viewBox="0 0 47 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="46"
        height="24.931"
        rx="2.5"
        stroke="#040D13"
      />
      <circle cx="24.3101" cy="12.9653" r="9.22414" stroke={fill} />
      <path
        d="M23.1732 18.4518V17.1918C21.7032 17.0658 20.6252 16.5058 19.8692 15.7358L20.9472 14.2238C21.4652 14.7698 22.2212 15.2598 23.1732 15.4278V13.2158C21.7312 12.8518 20.1632 12.3198 20.1632 10.4158C20.1632 9.00176 21.2832 7.79776 23.1732 7.60176V6.29976H24.5032V7.62976C25.6372 7.75576 26.5892 8.18976 27.3452 8.87576L26.2392 10.3318C25.7352 9.86976 25.1332 9.57576 24.5032 9.42176V11.3958C25.9452 11.7598 27.5552 12.3198 27.5552 14.2238C27.5552 15.7778 26.5472 16.9678 24.5032 17.1778V18.4518H23.1732ZM25.5392 14.4478C25.5392 13.9858 25.1052 13.7478 24.5032 13.5518V15.4278C25.1892 15.2878 25.5392 14.8818 25.5392 14.4478ZM22.1932 10.2478C22.1932 10.6538 22.5852 10.8778 23.1732 11.0458V9.33776C22.5432 9.42176 22.1932 9.77176 22.1932 10.2478Z"
        fill="#040D13"
      />
      <path
        d="M9.72326 25.5262C9.72326 25.5262 9.44186 22.0499 6.88706 19.4486C4.5516 17.0707 0.404297 17.0176 0.404297 17.0176"
        stroke="#040D13"
      />
      <path
        d="M0.809739 10.1295C0.809739 10.1295 4.28607 9.84811 6.88732 7.29331C9.26524 4.95785 9.31836 0.810547 9.31836 0.810547"
        stroke="#040D13"
      />
      <path
        d="M37.2767 0.810715C37.2767 0.810715 37.5581 4.28704 40.1129 6.8883C42.4484 9.26622 46.5957 9.31934 46.5957 9.31934"
        stroke="#040D13"
      />
      <path
        d="M46.5946 16.2064C46.5946 16.2064 43.1182 16.4878 40.517 19.0426C38.1391 21.3781 38.0859 25.5254 38.0859 25.5254"
        stroke="#040D13"
      />
    </svg>
  );
}

export default SvgComponent;
