import * as React from "react";
import { primaryColor } from "../../scss/colors.scss";

function SvgComponent({ fill = primaryColor }) {
  return (
    <svg
      width="31"
      height="29"
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66688 10.4258H4.61133V27.7591H8.94466"
        stroke="#040D13"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.6484 27.5182V18.8516H23.1484V27.5182"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M27.4824 28V7.77778L9.42687 2V28"
        stroke="#040D13"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 28H29.8889"
        stroke="#040D13"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
