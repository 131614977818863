import { Box, Grid, InputBase, makeStyles } from "@material-ui/core";
import React from "react";
import cx from "classnames";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import { useStyles as useSectionStyles } from "../../../../utils/loan-application.styles";
import { PersonIcon } from "../../../../svgs/loan-application";
import OptionsGroup from "../../components/OptionsGroup";
import Button from "../../components/Button";

const useStyles = makeStyles({
  disableHoverBackground: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  disableHover: {
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "default",
    },
  },
  numberInputContainer: {
    height: "fit-content",
    marginTop: "24px",
  },
  numberInput: {
    paddingTop: "14px",
    paddingBottom: "14px",
    width: "60px",
    "& input": {
      padding: "0",
      fontSize: "24px",
      lineHeight: "29px",
      fontWeight: "bold",
      width: "fit-content",
    },
  },
});

const citizenStatusOptions = [
  { value: "australian-citizen", label: "Australian Citizen" },
  { value: "permanent-resident", label: "Permanent Resident" },
  { value: "non-citizen", label: "Non Citizen" },
];

const dependantOptions = [
  {
    value: 1,
    label: (
      <>
        One Little Me
        <PersonIcon />
      </>
    ),
  },
  {
    value: 2,
    label: (
      <>
        Two Little Me’s
        <PersonIcon />
        <PersonIcon />
      </>
    ),
  },
  {
    value: 3,
    label: (
      <>
        Three Little Me’s
        <PersonIcon />
        <PersonIcon />
        <PersonIcon />
      </>
    ),
  },
];

const YourDetailsCitizenStatusStep = ({
  formik,
  handlePrevStep,
  handleNextStep,
}) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const onNextStep = () => {
    formik.setFieldTouched("citizenStatus");
    formik.setFieldTouched("dependants");
    if (formik.errors.citizenStatus || formik.errors.dependants) {
      return;
    }
    handleNextStep();
  };

  return (
    <Box display="flex" flexDirection="column" gridRowGap={48}>
      <Grid item container spacing={8} sm={8} md={6}>
        <OptionsGroup
          selectedValue={formik.values.citizenStatus}
          onSelect={(value) => formik.setFieldValue("citizenStatus", value)}
          title="Citizen Status"
          options={citizenStatusOptions}
          error={
            formik.touched.citizenStatus && Boolean(formik.errors.citizenStatus)
          }
          helperText={
            formik.touched.citizenStatus && formik.errors.citizenStatus
          }
        />
        <OptionsGroup
          selectedValue={formik.values.dependants}
          onSelect={(value) => formik.setFieldValue("dependants", value)}
          title="Dependants"
          options={dependantOptions}
        />
        <Grid
          item
          container
          xs={12}
          classes={{ root: classes.numberInputContainer }}
        >
          <Box
            classes={{
              root: cx(sectionClasses.option, classes.disableHoverBackground),
            }}
          >
            Add Number
          </Box>
          <Box
            classes={{
              root: cx(
                sectionClasses.option,
                classes.disableHover,
                classes.numberInput
              ),
            }}
          >
            <InputBase
              type="number"
              value={formik.values.dependants}
              onChange={(event) =>
                formik.setFieldValue("dependants", Number(event.target.value))
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={8}
        md={6}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button endIcon={<NavigateNextIcon />} onClick={onNextStep}>
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default YourDetailsCitizenStatusStep;
