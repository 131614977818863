import * as React from "react";
import { primaryColor } from "../../scss/colors.scss";

function SvgComponent(props, { fill = primaryColor }) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.49219 7.36719C10.4252 7.36719 11.9922 5.80018 11.9922 3.86719C11.9922 1.93419 10.4252 0.367188 8.49219 0.367188C6.55919 0.367188 4.99219 1.93419 4.99219 3.86719C4.99219 5.80018 6.55919 7.36719 8.49219 7.36719Z"
        fill={fill}
        fillOpacity="0.8"
      />
      <path
        d="M1.46282 14.6169C3.36882 14.6519 11.9379 14.6169 13.1699 14.6169C13.8159 14.6169 13.2812 13.0581 11.9042 11.4421C11.0472 10.4271 9.3924 9.32696 8.8554 9.61496C8.30559 9.94782 7.67391 10.1237 7.03118 10.1237C6.38846 10.1237 5.75873 9.94782 5.20892 9.61496C4.71492 9.23896 3.43559 9.94811 2.22259 11.4421C1.27059 12.6041 0.454824 14.5979 1.46282 14.6169Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M6.99219 9.11719C9.06326 9.11719 10.7422 7.43826 10.7422 5.36719C10.7422 3.29612 9.06326 1.61719 6.99219 1.61719C4.92112 1.61719 3.24219 3.29612 3.24219 5.36719C3.24219 7.43826 4.92112 9.11719 6.99219 9.11719Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default SvgComponent;
