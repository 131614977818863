import * as React from "react";
import { primaryColor } from "../../scss/colors.scss";

function SvgComponent(props, { fill = primaryColor }) {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 16.5H10.5M4.5 13H13.5M4 10.5H10.5M13.0858 1.08579L16.9142 4.91421C17.2893 5.28929 17.5 5.79799 17.5 6.32843V19.5C17.5 20.6046 16.6046 21.5 15.5 21.5H2.5C1.39543 21.5 0.5 20.6046 0.5 19.5V2.5C0.5 1.39543 1.39543 0.5 2.5 0.5H11.6716C12.202 0.5 12.7107 0.710713 13.0858 1.08579Z"
        stroke="black"
      />
      <circle cx="16.5" cy="18.5" r="7" stroke="black" />
      <path
        d="M16.6091 22V15L13.5 18"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6094 22V15L20 18"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
