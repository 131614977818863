import * as React from "react";
import { primaryColor } from "../../scss/colors.scss";

function SvgComponent({ fill = primaryColor }) {
  return (
    <svg
      width="47"
      height="27"
      viewBox="0 0 47 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H34.2747C36.0542 0.5 37.2639 2.30644 36.5864 3.95188L36.102 5.12823C35.6034 6.33918 35.8334 7.72805 36.6958 8.71362L37.012 9.07504C37.6325 9.78413 37.6379 10.8413 37.0247 11.5567C36.2136 12.503 36.099 13.862 36.7402 14.9307L37.2836 15.8362C37.7599 16.6301 37.5941 17.6518 36.8912 18.2543C35.9232 19.084 35.6111 20.4487 36.122 21.6166L36.4577 22.3839C37.1804 24.0356 35.9702 25.886 34.1673 25.886H3C1.61929 25.886 0.5 24.7667 0.5 23.386V3Z"
        stroke="#040D13"
      />
      <path
        d="M23.724 19.4V18.14C22.254 18.014 21.176 17.454 20.42 16.684L21.498 15.172C22.016 15.718 22.772 16.208 23.724 16.376V14.164C22.282 13.8 20.714 13.268 20.714 11.364C20.714 9.95 21.834 8.746 23.724 8.55V7.248H25.054V8.578C26.188 8.704 27.14 9.138 27.896 9.824L26.79 11.28C26.286 10.818 25.684 10.524 25.054 10.37V12.344C26.496 12.708 28.106 13.268 28.106 15.172C28.106 16.726 27.098 17.916 25.054 18.126V19.4H23.724ZM26.09 15.396C26.09 14.934 25.656 14.696 25.054 14.5V16.376C25.74 16.236 26.09 15.83 26.09 15.396ZM22.744 11.196C22.744 11.602 23.136 11.826 23.724 11.994V10.286C23.094 10.37 22.744 10.72 22.744 11.196Z"
        fill="#040D13"
      />
      <circle cx="24.7365" cy="13.1926" r="9.39474" stroke="#040D13" />
      <path
        d="M9.89457 25.9733C9.89457 25.9733 9.60823 22.436 7.0086 19.7891C4.63218 17.3695 0.412109 17.3154 0.412109 17.3154"
        stroke="black"
      />
      <path
        d="M0.824527 10.3067C0.824527 10.3067 4.36184 10.0203 7.00874 7.42071C9.42837 5.04429 9.48242 0.824219 9.48242 0.824219"
        stroke="black"
      />
      <rect
        x="45.3516"
        y="8.24512"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="45.3516"
        y="12.3682"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="45.3516"
        y="16.4912"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="38.7559"
        y="0.825195"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="38.7559"
        y="4.94824"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="38.7559"
        y="9.89551"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="38.7559"
        y="19.79"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="38.7559"
        y="23.9131"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="38.7559"
        y="14.8428"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="42.0527"
        y="20.6143"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="42.0527"
        y="12.3682"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="42.0527"
        y="8.24609"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="42.0527"
        y="16.4912"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
      <rect
        x="42.0527"
        y="4.12305"
        width="1.64912"
        height="1.64912"
        fill={fill}
      />
    </svg>
  );
}

export default SvgComponent;
