import * as React from "react";
import { primaryColor } from "../../scss/colors.scss";

function SvgComponent({ fill = primaryColor }) {
  return (
    <svg
      width="35"
      height="30"
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.73242 28.5313H26.9401V24.6943H2.73242V28.5313Z"
        stroke="#040D13"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.64335 11.0664V23.9153H4.25977V11.0664H4.64335Z"
        fill="#959495"
        stroke="#040D13"
      />
      <path
        d="M8.09257 11.0664V23.9153H7.70898V11.0664H8.09257Z"
        fill="#959495"
        stroke="#040D13"
      />
      <path
        d="M21.7918 11.0664V23.9153H21.4082V11.0664H21.7918Z"
        fill="#959495"
        stroke="#040D13"
      />
      <path
        d="M25.3133 11.0664V23.9153H24.9297V11.0664H25.3133Z"
        fill="#959495"
        stroke="#040D13"
      />
      <path
        d="M14.6514 1L28.3027 10.8906H1L14.6514 1Z"
        stroke="#040D13"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6973 27.1124V18.3877C15.6973 17.8354 16.145 17.3877 16.6973 17.3877H18.7281L32.8268 17.7156C33.3547 17.7278 33.7821 18.1485 33.8028 18.6761L33.9991 23.6826V27.1124C33.9991 27.6646 33.5514 28.1124 32.9991 28.1124H16.6973C16.145 28.1124 15.6973 27.6646 15.6973 27.1124Z"
        fill="white"
        stroke="#040D13"
        strokeLinejoin="round"
      />
      <path
        d="M15.8145 20.5325C15.8145 20.5325 17.228 20.2393 17.7962 19.5999C18.3413 18.9864 18.4956 17.6182 18.4956 17.6182"
        stroke="#040D13"
        strokeLinejoin="round"
      />
      <path
        d="M30.8513 17.6182C30.8513 17.6182 31.1445 19.0317 31.7839 19.5999C32.3974 20.145 33.7656 20.2993 33.7656 20.2993"
        stroke="#040D13"
        strokeLinejoin="round"
      />
      <path
        d="M33.7656 25.0828C33.7656 25.0828 32.352 25.376 31.7839 26.0153C31.2387 26.6288 31.0845 27.9971 31.0845 27.9971"
        stroke="#040D13"
        strokeLinejoin="round"
      />
      <path
        d="M18.8459 27.9971C18.8459 27.9971 18.5527 26.5835 17.9134 26.0153C17.2999 25.4702 15.9316 25.3159 15.9316 25.3159"
        stroke="#040D13"
        strokeLinejoin="round"
      />
      <path
        d="M20.504 26H19.568V20.664H20.504V26ZM24.1263 26.096C22.5263 26.096 21.3983 24.928 21.3983 23.336C21.3983 21.744 22.5263 20.576 24.1263 20.576C25.7263 20.576 26.8543 21.744 26.8543 23.336C26.8543 24.928 25.7263 26.096 24.1263 26.096ZM24.1263 25.264C25.1983 25.264 25.8943 24.432 25.8943 23.336C25.8943 22.232 25.1983 21.408 24.1263 21.408C23.0463 21.408 22.3583 22.232 22.3583 23.336C22.3583 24.432 23.0463 25.264 24.1263 25.264ZM30.0675 26.096C28.5155 26.096 27.7555 25.208 27.7555 23.896V20.664H28.6995V23.864C28.6995 24.712 29.1715 25.264 30.0675 25.264C30.9555 25.264 31.4275 24.712 31.4275 23.864V20.664H32.3715V23.888C32.3715 25.216 31.6115 26.096 30.0675 26.096Z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgComponent;
