import * as React from "react";
import { primaryColor } from "../../scss/colors.scss";

function SvgComponent({ fill = primaryColor }) {
  return (
    <svg
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="24" height="27" rx="1.5" stroke="black" />
      <line x1="3" y1="3.5" x2="17" y2="3.5" stroke={fill} />
      <line x1="3" y1="9.5" x2="12" y2="9.5" stroke="black" />
      <line x1="3" y1="15.5" x2="15" y2="15.5" stroke={fill} />
      <line x1="3" y1="21.5" x2="15" y2="21.5" stroke="black" />
    </svg>
  );
}

export default SvgComponent;
